<template>
    <md-chips v-model="localTags" md-placeholder="Add tags...">
        <label>Tags</label>
    </md-chips>
</template>

<script>
export default {
    name: 'TagsFilter',

    props: {
        selectedTags: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            localTags: [],
        };
    },

    watch: {
        selectedTags: {
            immediate: true,
            handler(newValue) {
                if (JSON.stringify(this.localTags) !== JSON.stringify(newValue)) {
                    this.localTags = [...newValue];
                }
            },
        },
        localTags: {
            handler(newValue) {
                this.$emit('tags-filter-changed', newValue);
            },
        },
    },
};
</script>

<style scoped>
.tags-filter-field {
    display: flex;
    align-items: center;
}
</style>
