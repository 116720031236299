<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MainAppsShopifyShopInfo',
    data() {
        return {
            copiedStates: {},
        };
    },
    computed: {
        ...mapGetters({
            getSkillByInternalName: 'agentSettings/getSkillByInternalName',
        }),
        shopifySkill() {
            return this.getSkillByInternalName('Shopify');
        },
        shopInfo() {
            const { config_values } = this.shopifySkill;
            return {
                businessName: config_values.businessName,
                email: config_values.email,
                myShopifyDomain: config_values.shop,
                shop: config_values.publicDomain ?? config_values.shop,
                source: config_values.source,
            };
        },
    },
    methods: {
        formatLabel(key) {
            return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
        },
        copyToClipboard(key, value) {
            navigator.clipboard
                .writeText(value)
                .then(() => {
                    this.$set(this.copiedStates, key, true);
                    setTimeout(() => {
                        this.$set(this.copiedStates, key, false);
                    }, 700);
                })
                .catch((err) => {
                    console.error('Failed to copy text: ', err);
                });
        },
    },
};
</script>

<template>
    <md-card class="shop-info">
        <md-card-header>
            <div class="md-title"><strong>Shop Information</strong></div>
        </md-card-header>
        <md-card-content>
            <ul class="shop-info__list">
                <li v-for="(value, key) in shopInfo" :key="key" class="shop-info__item">
                    <div class="shop-info__content">
                        <strong class="shop-info__label md-body-2">{{ formatLabel(key) }}:</strong>
                        <span class="shop-info__value md-body-1">{{ value }}</span>
                    </div>
                    <i
                        class="shop-info__copy-icon material-symbols-outlined"
                        :class="{ copied: copiedStates[key] }"
                        @click="copyToClipboard(key, value)"
                    >
                        {{ copiedStates[key] ? 'check' : 'content_copy' }}
                    </i>
                </li>
            </ul>
        </md-card-content>
    </md-card>
</template>

<style lang="scss" scoped>
.shop-info {
    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        padding: 12px 0;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            border-bottom: none;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        flex: 1;
    }

    &__label {
        flex: 0 0 140px;
        color: #666;
        margin-right: 8px;
    }

    &__value {
        flex: 1;
        word-break: break-word;
    }

    &__copy-icon {
        cursor: pointer;
        font-size: 18px;
        color: #999;
        margin-left: 8px;
        transition: all 0.3s ease;
        flex-shrink: 0;

        &:hover {
            color: #333;
        }

        &.copied {
            animation: copy-animation 0.3s ease;
        }
    }
}

@keyframes copy-animation {
    0% {
        opacity: 0;
        transform: rotate(-180deg);
    }
    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}

@media (max-width: 600px) {
    .shop-info {
        &__item {
            flex-direction: column;
            align-items: flex-start;
        }

        &__content {
            width: 100%;
            margin-bottom: 8px;
        }

        &__label {
            flex: 0 0 auto;
            margin-bottom: 0;
        }

        &__copy-icon {
            align-self: flex-end;
        }
    }
}
</style>
