// conversationsService.js

import axios from 'axios';
import store from '@/store';

const baseURL = `${process.env.VUE_APP_API_URL}`;
// const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

let cancelToken = axios.CancelToken.source();

const apiRequest = async (method, url, data = null, params = {}, cancelTokenSource = null) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params: params instanceof URLSearchParams ? params : { ...params },
                data: data,
            };
            if (cancelTokenSource) {
                config.cancelToken = cancelTokenSource.token;
            }
            return await axios(config);
        });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled: ', error.message);
        } else {
            throw error;
        }
    }
};

const conversationsService = {
    fetchRooms() {
        return apiRequest('get', 'conversations');
    },

    fetchConversations(page = 0, size = 20, timestamp = null, filters = null) {
        const params = new URLSearchParams();
        params.append('page', page);
        params.append('size', size);
        if (timestamp) params.append('timestamp', timestamp);

        if (filters) {
            Object.entries(filters).forEach(([key, values]) => {
                if (key === 'excludedTags') {
                    values.forEach((value) => {
                        params.append('filters.excludedTag', value);
                    });
                } else {
                    values.forEach((value) => {
                        params.append(`filters.${key}`, value);
                    });
                }
            });
        }

        return apiRequest('get', 'conversations', null, params);
    },

    getConversation(conversationId) {
        return apiRequest('get', `conversations/${conversationId}`);
    },

    fetchMessages(conversationId, page = 0, size = 20) {
        const params = { page, size };
        return apiRequest('get', `conversations/${conversationId}/messages`, null, params);
    },

    getMessage(messageId) {
        return apiRequest('get', `messages/${messageId}`);
    },

    sendMessage(messageData) {
        return apiRequest('post', 'messages', messageData);
    },

    fetchOrders(customerId, conversationId) {
        return apiRequest('get', `customers/${customerId}/orders`, null, { conversation: conversationId });
    },

    fetchAssets(customerId) {
        return apiRequest('get', `customers/${customerId}/assets`);
    },

    async searchRooms(pattern, page = 0, size = 20) {
        try {
            // Handle the '#' character in the pattern
            let encodedSearchPattern = pattern;
            if (pattern.startsWith('#')) {
                const searchPattern = pattern.substring(1);
                encodedSearchPattern = `%23${encodeURIComponent(searchPattern)}`;
            }

            // Cancel the previous request if it exists
            if (cancelToken) {
                cancelToken.cancel('Operation canceled due to new request.');
            }
            cancelToken = axios.CancelToken.source();

            const params = { page, size, pattern: encodedSearchPattern };

            return apiRequest('get', 'conversations/search', null, params, cancelToken);
        } catch (error) {
            throw error;
        }
    },

    getNotesOrder(orderName) {
        return apiRequest('get', `orders/${orderName}/notes`);
    },

    getAssetNotes(customerId, assetName) {
        return apiRequest('get', `customers/${customerId}/assets/${assetName}/notes`);
    },

    updateStateAI(conversationId, aiEnabled) {
        const data = { ai_enabled: aiEnabled, conversation: conversationId };
        return apiRequest('post', `conversations/${conversationId}/ai-configuration`, data);
    },

    getProductInfo(productId) {
        return apiRequest('get', `catalogs/${store.state.user.user.account_id}/items/${productId}/info`);
    },

    async getStorageURL(channelId, typeFile, fileName) {
        try {
            const uniqueName = `${new Date().getTime()}-${fileName}`;

            const response = await apiRequest('get', 'messages/media/upload-url', null, {
                channelId,
                contentType: typeFile,
                fileName: uniqueName,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    clearConversation(conversationId) {
        return apiRequest('post', `conversations/${conversationId}/clean`);
    },

    scaleAgent(agent, conversationId) {
        const reqBody = {
            account: store.state.user.user.account_id,
            conversation: conversationId,
            ai_type: agent,
        };

        return apiRequest('post', `conversations/${conversationId}/scale-agent`, reqBody);
    },

    getCopilotCorrection(conversation_id, message, previousMessage = null) {
        const reqData = {
            account_id: store.state.user.user.account_id,
            conversation_id,
            message,
            context: previousMessage ? previousMessage : message,
        };
        return apiRequest('post', 'messages/copilot', reqData);
    },

    setConversationNotes(conversationId, note) {
        const reqData = {
            note,
        };

        return apiRequest('post', `conversations/${conversationId}/note`, reqData);
    },

    setConversationAsRead(conversationId) {
        const reqData = {
            account_id: store.state.user.user.account_id,
            conversation_id: conversationId,
            read: true,
        };
        return apiRequest('post', `conversations/${conversationId}/mark-as-read`, reqData);
    },

    setConversationAsUnread(conversationId) {
        const reqData = {
            account_id: store.state.user.user.account_id,
            conversation_id: conversationId,
            read: true,
        };
        return apiRequest('post', `conversations/${conversationId}/mark-as-unread`, reqData);
    },

    updateConversationTags(conversationId, tags) {
        const reqData = {
            tags: [...tags],
        };
        return apiRequest('post', `conversations/${conversationId}/tags`, reqData);
    },
};

export default conversationsService;
