<template>
    <div class="active-filters">
        <md-chip
            class="active-filter"
            :md-deletable="deletable"
            :md-clickable="deletable"
            @md-delete="removeFilter(filter.value)"
            v-for="filter in regularFilters"
            :key="filter.value"
        >
            <div>{{ transformFilter(filter.value) }}</div>
        </md-chip>

        <TagChip
            v-for="tagId in activeFilters.tags || []"
            :key="`included-${tagId}`"
            :uuid="tagId"
            @remove="removeTag(tagId)"
            :deletable="deletable"
        />

        <TagChip
            v-for="tagId in activeFilters.excludedTags || []"
            :key="`excluded-${tagId}`"
            :uuid="tagId"
            @remove="removeExcludedTag(tagId)"
            :deletable="deletable"
            class="excluded-tag"
        />
    </div>
</template>

<script>
import TagChip from '../TagChip/TagChip.vue';

export default {
    name: 'ActiveFilters',

    components: {
        TagChip,
    },

    emits: ['remove-filter'],

    props: {
        activeFilters: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        deletable: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        regularFilters() {
            return Object.entries(this.activeFilters)
                .filter(([key]) => !['tags', 'excludedTags'].includes(key))
                .map(([key, value]) => ({ key, value }));
        },
    },

    methods: {
        transformFilter(filter) {
            return filter.toLowerCase().replace(/_/g, ' ');
        },

        removeFilter(filter) {
            this.$emit('remove-filter', filter);
        },

        removeTag(tagId) {
            this.$emit('remove-filter', [tagId]);
        },

        removeExcludedTag(tagId) {
            this.$emit('remove-filter', [tagId]);
        },
    },
};
</script>

<style lang="scss" scoped>
.active-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
}

.excluded-tag {
    opacity: 0.8;
    text-decoration: line-through;
}
</style>
