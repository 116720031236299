<template>
    <div class="filters-sidebar" :class="{ 'filters-sidebar--open': value }">
        <div class="filters-sidebar__content">
            <div class="filters-sidebar__header">
                <div class="md-title">Filters</div>
                <button class="filters-sidebar__close" @click="closeSidebar">
                    <span>&times;</span>
                </button>
            </div>

            <div class="filters-sidebar__body">
                <div class="filters-sidebar__filter-title no-margin">
                    <svg-icon name="tag_icon" class="tag-selector__icon" />
                    <div class="md-body-2">Include Tags</div>
                </div>
                <TagSelector :preselected-tags="localFilters.tags" @update:selected="handleTagsFilterChange" />
                <div class="filters-sidebar__filter-title">
                    <svg-icon name="tag_icon" class="tag-selector__icon" />
                    <div class="md-body-2">Exclude Tags</div>
                </div>
                <TagSelector
                    :preselected-tags="localFilters.excludedTags"
                    @update:selected="handleExcludedTagsFilterChange"
                    label="Exclude Tags"
                />
                <div class="filters-sidebar__filter-title">
                    <svg-icon name="smart_toy" class="tag-selector__icon" />
                    <div class="md-body-2">Agent Type</div>
                </div>
                <agent-filter :selected-agent="localFilters.agent" @agent-filter-changed="handleAgentFilterChange" />
                <div class="filters-sidebar__filter-title">
                    <svg-icon name="smile_plus" class="tag-selector__icon" />
                    <div class="md-body-2">Sentiment</div>
                </div>
                <sentiment-filter
                    :selected-sentiment="localFilters.sentiment"
                    @sentiment-filter-changed="handleSentimentFilterChange"
                />
            </div>

            <div v-if="hasFilters" class="filters-sidebar__footer">
                <md-button class="md-block md-primary" @click="applyFilters">Apply Filters</md-button>
            </div>
        </div>
    </div>
</template>

<script>
import AgentFilter from './AgentFilter.vue';
import SentimentFilter from './SentimentFilter.vue';
import TagsFilter from './TagsFilter.vue';
import TagSelector from '@/lib/RoomsList/FiltersSideBar/TagSelector.vue';
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'FiltersSideBar',
    components: {
        AgentFilter,
        SentimentFilter,
        TagsFilter,
        TagSelector,
        SvgIcon,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        filters: {
            type: Object,
            default: () => ({
                agent: '',
                sentiment: '',
                tags: [],
            }),
        },
    },
    data() {
        return {
            localFilters: {
                agent: '',
                sentiment: '',
                tags: [],
                excludedTags: [],
            },
        };
    },
    computed: {
        hasFilters() {
            return Object.values(this.localFilters).some((value) => value !== '');
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    setTimeout(() => {
                        document.addEventListener('click', this.handleGlobalClick);
                    }, 0);
                } else {
                    document.removeEventListener('click', this.handleGlobalClick);
                }
            },
        },
        filters: {
            immediate: true,
            handler(newFilters) {
                this.localFilters = { ...newFilters };
            },
        },
    },
    methods: {
        handleTagSelection(selectedTags) {
            console.log('Selected tags:', selectedTags);
        },
        handleAgentFilterChange(agentCode) {
            this.$set(this.localFilters, 'agent', agentCode);
        },
        handleSentimentFilterChange(sentiment) {
            this.$set(this.localFilters, 'sentiment', sentiment);
        },
        handleTagsFilterChange(tags) {
            console.log('Tags:', tags);
            this.$set(this.localFilters, 'tags', [...tags]);
        },
        handleExcludedTagsFilterChange(excludedTags) {
            this.$set(this.localFilters, 'excludedTags', [...excludedTags]);
        },
        closeSidebar() {
            this.$emit('input', false);
        },
        handleGlobalClick(event) {
            if (this.value && !this.$el.contains(event.target)) {
                this.closeSidebar();
            }
        },
        applyFilters() {
            this.$emit('filters-applied', { ...this.localFilters });
            this.closeSidebar();
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleGlobalClick);
    },
};
</script>

<style scoped lang="scss">
.filters-sidebar {
    position: absolute;
    left: -250px;
    top: 0;
    bottom: 0;
    width: 250px;
    background-color: #fff;
    transition: left 0.3s ease;
    z-index: 10;
}

.filters-sidebar--open {
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    left: 0;
}

.filters-sidebar__content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.filters-sidebar__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px;
    border-bottom: 1px solid #e0e0e0;
}

.filters-sidebar__close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0 5px;
}

.filters-sidebar__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    /* gap: 0.5rem; */
}

.filters-sidebar__footer {
    padding: 15px 20px;
    border-top: 1px solid #e0e0e0;
}

.filters-sidebar__save {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.filters-sidebar__save:hover {
    background-color: #0056b3;
}

.filters-sidebar__filter-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 1.5rem;

    .tag-selector__icon {
        width: 18px;
        height: 18px;
    }

    &.no-margin {
        margin-top: 0;
    }
}
</style>
