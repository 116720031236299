<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'RoomListToolBar',
    components: {
        SvgIcon,
    },
    computed: {
        ...mapGetters('agentSettings', ['isAgentSettingsEnabled']),
        isLixsaEnabled: {
            get() {
                return this.isAgentSettingsEnabled;
            },
            set(value) {
                this.switchAgentSettings(value).catch((error) => {
                    console.error('Failed to switch agent settings:', error);
                });
            },
        },
        hasWhatsappSkill() {
            return this.$store.getters['agentSettings/getSkillByInternalName']('whatsapp') || false;
        },
    },
    methods: {
        ...mapActions('agentSettings', ['switchAgentSettings']),
    },
};
</script>

<template>
    <div class="room-list-tool-bar">
        <div class="md-title">Inbox</div>
        <div v-if="hasWhatsappSkill" class="room-list-tool-bar__actions">
            <md-switch :class="{ 'lixsa-disabled': !isLixsaEnabled }" v-model="isLixsaEnabled"
                ><span>{{ isLixsaEnabled ? 'Lixsa Enabled' : 'Lixsa Disabled' }}</span></md-switch
            >
        </div>
    </div>
</template>

<style>
.room-list-tool-bar {
    height: 64px;
    width: 100%;
    border-bottom: 1px solid #e1e4e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}

.room-list-tool-bar__svg-icon {
    width: 13px;
    height: 13px;
}

.room-list-tool-bar__text {
    margin-left: 5px;
}

.lixsa-disabled {
    span {
        color: #9ca6af;
    }
}
</style>
