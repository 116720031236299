<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'FiltersPresetsList',
    emits: ['modalEvent'],
    data() {
        return {
            selectedPresetName: null,
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters('activeFilters', ['presets']),
        hasPresets() {
            return this.presets.length > 0;
        },
        presetNames() {
            return this.presets.map((preset) => preset.name);
        },
        selectedPresetId() {
            const selectedPreset = this.presets.find((preset) => preset.name === this.selectedPresetName);
            return selectedPreset ? selectedPreset.id : null;
        },
    },
    methods: {
        ...mapActions('activeFilters', ['removePreset', 'applyPreset']),
        ...mapActions('modalStore', ['closeModal']),

        async handleRemovePreset(presetName, event) {
            event.stopPropagation();
            const preset = this.presets.find((p) => p.name === presetName);
            if (!preset) return;

            try {
                await this.removePreset(preset.id);
                this.$toasted.success('Preset removed successfully', {
                    position: 'bottom-center',
                    duration: 3000,
                });
                if (this.selectedPresetName === presetName) {
                    this.selectedPresetName = null;
                }
            } catch (error) {
                this.$toasted.error('Failed to remove preset', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            }
        },

        async handleApplyPreset() {
            if (!this.selectedPresetId) return;

            this.isLoading = true;
            try {
                await this.applyPreset(this.selectedPresetId);
                this.$toasted.success('Filters applied successfully', {
                    position: 'bottom-center',
                    duration: 3000,
                });
                this.$emit('modalEvent', { eventName: 'preset-applied', eventPayload: '' });
                this.closeModal();
            } catch (error) {
                this.$toasted.error('Failed to apply preset', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<template>
    <div class="presets-list">
        <div class="presets-list__header">
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>

        <md-content class="presets-list__content">
            <div class="presets-list__title-container">
                <span class="material-symbols-outlined presets-list__icon">bookmarks</span>
                <p class="presets-list__title md-title">Filter Lists</p>
            </div>

            <div v-if="!hasPresets" class="presets-list__empty">
                <span class="material-symbols-outlined empty-icon">bookmark_border</span>
                <p>No saved presets</p>
            </div>

            <div v-else class="presets-list__selector">
                <md-field>
                    <label>Select Filter Preset</label>
                    <md-select v-model="selectedPresetName" name="preset" id="preset">
                        <md-option v-for="name in presetNames" :key="name" :value="name">
                            {{ name }}
                            <div class="preset-delete-button" @click.stop="handleRemovePreset(name, $event)">
                                <md-icon>delete</md-icon>
                            </div>
                        </md-option>
                    </md-select>
                </md-field>
            </div>

            <div class="presets-list__actions">
                <md-button
                    @click="handleApplyPreset"
                    class="md-raised md-primary"
                    :disabled="!selectedPresetId || isLoading"
                >
                    {{ isLoading ? 'Applying...' : 'Apply filters' }}
                </md-button>
                <md-button @click="closeModal()" class="md-gray md-outline">Cancel</md-button>
            </div>
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.presets-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
        width: 400px;
        max-width: 400px;
        margin: 0 auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 0.5rem 0 1rem;
        background-color: #ffffff;
    }

    &__content {
        flex-grow: 1;
        padding: 0 2rem;
        overflow-y: auto;
    }

    &__title {
        font-size: 22px;
        font-weight: 500;
        margin: 0;
    }

    &__title-container {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__icon {
        font-size: 24px;
        margin-right: 8px;
    }

    &__empty {
        text-align: center;
        padding: 2rem;
        color: rgba(0, 0, 0, 0.54);

        .empty-icon {
            font-size: 48px;
            margin-bottom: 1rem;
        }
    }

    &__selector {
        margin: 1rem 0;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        margin-bottom: 1rem;
        gap: 16px;
    }
}

.preset-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 8px;

    .md-icon-button {
        margin: -8px;
    }
}

.preset-delete-button {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        background-color: rgba(0, 0, 0, 0.08);
    }

    .md-icon {
        font-size: 18px !important;
        color: rgba(0, 0, 0, 0.54);
        transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

::v-deep .md-list-item-content {
    &:hover {
        .preset-delete-button .md-icon {
            color: white;
        }
    }
}

::v-deep .md-list-item-text {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

::v-deep .md-select-value {
    min-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
}
</style>
