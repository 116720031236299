import BaseDto from '../../_common/BaseDto';

class TagDtoBuilder {
    withUuid(uuid) {
        this.uuid = uuid;
        return this;
    }

    withAccountId(accountId) {
        this.account_id = accountId;
        return this;
    }

    withName(name) {
        this.name = name;
        return this;
    }

    withColor(color) {
        this.color = color;
        return this;
    }

    build() {
        return new TagDto(this);
    }
}

class TagDto extends BaseDto {
    constructor(builder) {
        super();
        this.uuid = builder.uuid;
        this.account_id = builder.account_id;
        this.name = builder.name;
        this.color = builder.color;
    }

    static get Builder() {
        return TagDtoBuilder;
    }
}

export default TagDto;
