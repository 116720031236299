<script>
export default {
    name: 'AuthBanner',
};
</script>

<template>
    <div class="auth-banner">
        <div class="auth-banner__image-container">
            <img src="@/assets/icons/LixsaLogo-complete-white-new.png" alt="Lixsa Logo" class="auth-banner__image" />
        </div>
        <div class="auth-banner__title-section">
            <div class="md-display-3 auth-banner__text">
                <strong> Connect with Intelligence </strong>
            </div>
            <div class="md-display-3 auth-banner__text--highlight">
                <strong> #withLixsa </strong>
            </div>
        </div>
        <div class="md-headline auth-banner__text">
            <strong>Boost your sales, reduce operational costs, and improve customer satisfaction.</strong>
        </div>

        <div class="md-title auth-banner__text">
            <strong> Start now for free </strong>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/md/_variables.scss';

.auth-banner {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    padding: 0 100px;

    &__image-container {
        position: absolute;
        top: 50px;
        left: 51px;
        //padding: 30px 20px;
    }

    &__title-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__text {
        color: white !important;
        text-align: center;

        &--highlight {
            color: $brand-success !important;
        }
    }

    &__image {
        width: 130px;
    }
}
</style>
