<template>
    <md-field class="agent-filter-field">
        <svg-icon class="filter-icon" :name="agent_icon(localAgentFilter)" />
        <!-- <label for="agent-type">Agent Type</label> -->
        <md-select id="agent-type" v-model="localAgentFilter">
            <md-option v-for="option in agentsFilterOptions" :key="option" :value="option">
                {{ getAgentFilterName(option) }}
            </md-option>
        </md-select>
    </md-field>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'AgentFilter',
    components: { SvgIcon },

    props: {
        selectedAgent: {
            type: String,
            default: 'ALL',
        },
    },

    data() {
        return {
            localAgentFilter: 'All',
            agentsFilterOptions: ['All', 'Support Agent', 'Sales Agent', 'Human Agent', 'Returns Agent'],
        };
    },

    watch: {
        selectedAgent: {
            immediate: true,
            handler(newValue) {
                // Convert the code back to display value
                this.localAgentFilter = this.getAgentDisplayValue(newValue);
            },
        },
        localAgentFilter() {
            const filterValue = this.getActiveAgentFilterCode();
            this.$emit('agent-filter-changed', filterValue);
        },
    },

    methods: {
        getAgentDisplayValue(code) {
            switch (code) {
                case 'SUPPORT_AGENT':
                    return 'Support Agent';
                case 'SALES_AGENT':
                    return 'Sales Agent';
                case 'HUMAN_AGENT':
                    return 'Human Agent';
                case 'RETURN_EXCHANGE_AGENT':
                    return 'Returns Agent';
                default:
                    return 'All';
            }
        },

        getAgentFilterName(agent) {
            if (agent === 'All') return 'All Agents';
            return agent;
        },

        agent_icon(agent) {
            let iconName;
            switch (agent) {
                case 'Support Agent':
                    iconName = 'SUPPORT_AGENT';
                    break;
                case 'Sales Agent':
                    iconName = 'SALES_AGENT';
                    break;
                case 'Human Agent':
                    iconName = 'HUMAN_AGENT';
                    break;
                case 'Returns Agent':
                    iconName = 'RETURN_EXCHANGE_AGENT';
                    break;
                default:
                    iconName = 'FILTER_ALL';
                    break;
            }
            return iconName;
        },

        getActiveAgentFilterCode() {
            switch (this.localAgentFilter) {
                case 'Support Agent':
                    return 'SUPPORT_AGENT';
                case 'Sales Agent':
                    return 'SALES_AGENT';
                case 'Human Agent':
                    return 'HUMAN_AGENT';
                case 'Returns Agent':
                    return 'RETURN_EXCHANGE_AGENT';
                default:
                    return 'ALL';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-field {
    margin-top: 0;
}

.agent-filter-field {
    display: flex;
    align-items: center;
}

.filter-icon {
    height: 15px;
    width: 15px;
    margin-right: 8px;
    fill: $mdb-text-color-primary;
}
</style>
