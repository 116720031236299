<script>
import { mapActions, mapGetters } from 'vuex';
import ActiveFilters from '../ActiveFilters/ActiveFilters.vue';

export default {
    name: 'SaveFiltersPreset',
    components: {
        ActiveFilters,
    },
    props: {
        activeFilters: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: true,
            presetName: '',
            nameError: '',
            tagsMap: new Map(), // Cache for tag data
            tagNamesCache: {}, // Cache for tag names
            isSaving: false,
        };
    },
    computed: {
        ...mapGetters('activeFilters', ['presets']),
        isValid() {
            return this.presetName.trim().length > 0;
        },
    },
    methods: {
        ...mapActions('activeFilters', ['savePreset']),
        ...mapActions('modalStore', ['closeModal']),

        async loadTagNames(tagIds) {
            if (!tagIds || !tagIds.length) return 'No tags';

            const cacheKey = tagIds.join(',');
            if (this.tagNamesCache[cacheKey]) {
                return this.tagNamesCache[cacheKey];
            }

            try {
                const tagPromises = tagIds.map(async (id) => {
                    if (!this.tagsMap.has(id)) {
                        const tag = await tagsApiRepository.getTag(id);
                        this.tagsMap.set(id, tag);
                    }
                    return this.tagsMap.get(id).name;
                });

                const tagNames = await Promise.all(tagPromises);
                const result = tagNames.join(', ');
                this.tagNamesCache[cacheKey] = result;
                return result;
            } catch (error) {
                console.error('Error loading tag names:', error);
                return 'Error loading tags';
            }
        },

        getTagNamesFromCache(tagIds) {
            if (!tagIds || !tagIds.length) return 'No tags';
            const cacheKey = tagIds.join(',');
            return this.tagNamesCache[cacheKey] || 'Loading...';
        },

        validateName() {
            this.nameError = '';
            if (!this.presetName.trim()) {
                this.nameError = 'Preset name is required';
                return false;
            }
            if (this.presets.some((preset) => preset.name === this.presetName.trim())) {
                this.nameError = 'A preset with this name already exists';
                return false;
            }
            return true;
        },

        async handleSave() {
            if (!this.validateName() || this.isSaving) return;

            this.isSaving = true;
            try {
                await this.savePreset({
                    name: this.presetName.trim(),
                    filters: this.activeFilters,
                });
                this.$toasted.success('Filter preset saved successfully', {
                    position: 'bottom-center',
                    duration: 3000,
                });
                this.closeModal();
            } catch (error) {
                console.error('Error saving preset:', error);
                this.$toasted.error('Failed to save preset. Please try again.', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            } finally {
                this.isSaving = false;
            }
        },
    },
    async created() {
        this.isLoading = false;
    },
};
</script>

<template>
    <div class="save-preset">
        <div class="save-preset__header">
            <div class="save-preset__title-container">
                <span class="material-symbols-outlined save-preset__icon">bookmark_add</span>
                <p class="save-preset__title md-title">Save Filter Preset</p>
            </div>
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-content class="save-preset__content">
            <div class="preset-form">
                <md-field :class="{ 'md-invalid': nameError }">
                    <label>Preset Name</label>
                    <md-input v-model="presetName" required @input="nameError = ''"></md-input>
                    <span class="md-error">{{ nameError }}</span>
                </md-field>

                <div class="current-filters">
                    <p class="md-subheading bold">Current Filters:</p>
                    <ActiveFilters :active-filters="activeFilters" :deletable="false" class="filters-preview" />
                </div>
            </div>

            <div class="save-preset__actions">
                <md-button @click="handleSave" class="md-raised md-primary" :disabled="isLoading || !isValid">
                    {{ isLoading ? 'Saving...' : 'Save Preset' }}
                </md-button>
                <md-button @click="closeModal()" class="md-gray md-outline">Cancel</md-button>
            </div>
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.save-preset {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
        width: 500px;
        max-width: 500px;
        margin: 0 auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0.5rem 0 1rem;
        background-color: #ffffff;
    }

    &__title {
        font-size: 22px;
        font-weight: 500;
        margin: 0;
        // color: #1976d2;
    }

    &__content {
        flex-grow: 1;
        padding: 0 2rem;
        overflow-y: auto;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        margin-bottom: 1rem;
        gap: 16px;
    }

    &__title-container {
        display: flex;
        align-items: center;
        // margin-bottom: 2rem;
    }

    &__icon {
        // color: #1976d2;
        font-size: 24px;
        margin-right: 8px;
    }
}

.preset-form {
    margin-top: 2rem;
}

.current-filters {
    margin-top: 1rem;
}

.active-filters {
    padding: 0;
    margin: 0;
}

.bold {
    font-weight: bold;
}
</style>
