import axios from 'axios';
import store from '@/store';
import TagDto from './Dto/TagDto';
// eslint-disable-next-line no-unused-vars
import TagRequestDto from './Dto/TagRequestDto';

const baseURL = `${process.env.VUE_APP_API_URL}`;

const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;
    return devMode ? { 'X-API-KEY': `${token}` } : { Authorization: `Bearer ${token}` };
};

const apiRequest = async (method, url, data = null) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const tagsApiRepository = {
    /**
     * Get all tags for the current account
     * @returns {Promise<TagDto[]>} Array of TagDto objects
     */
    getTags: async () => {
        const response = await apiRequest('GET', 'tags');
        return response.data.map((tag) =>
            new TagDto.Builder()
                .withUuid(tag.uuid)
                .withAccountId(tag.account_id)
                .withName(tag.name)
                .withColor(tag.color)
                .build(),
        );
    },

    /**
     * Create a new tag
     * @param {TagRequestDto} request - The tag creation request
     * @returns {Promise<TagDto>} The created tag
     */
    createTag: async (request) => {
        const response = await apiRequest('POST', 'tags', request.toJSON());
        const tag = response.data;
        return new TagDto.Builder()
            .withUuid(tag.uuid)
            .withAccountId(tag.account_id)
            .withName(tag.name)
            .withColor(tag.color)
            .build();
    },

    /**
     * Get a specific tag by ID
     * @param {string} tagId - The ID of the tag to retrieve
     * @returns {Promise<TagDto>} The requested tag
     */
    getTag: async (tagId) => {
        const response = await apiRequest('GET', `tags/${tagId}`);
        const tag = response.data;
        return new TagDto.Builder()
            .withUuid(tag.uuid)
            .withAccountId(tag.account_id)
            .withName(tag.name)
            .withColor(tag.color)
            .build();
    },

    /**
     * Update a specific tag
     * @param {string} tagId - The ID of the tag to update
     * @param {TagRequestDto} request - The tag update request
     * @returns {Promise<TagDto>} The updated tag
     */
    updateTag: async (tagId, request) => {
        const response = await apiRequest('POST', `tags/${tagId}`, request.toJSON());
        const tag = response.data;
        return new TagDto.Builder()
            .withUuid(tag.uuid)
            .withAccountId(tag.account_id)
            .withName(tag.name)
            .withColor(tag.color)
            .build();
    },

    /**
     * Delete a specific tag
     * @param {string} tagId - The ID of the tag to delete
     * @returns {Promise<TagDto>} The deleted tag
     */
    deleteTag: async (tagId) => {
        const response = await apiRequest('DELETE', `tags/${tagId}`);
        const tag = response.data;
        return new TagDto.Builder()
            .withUuid(tag.uuid)
            .withAccountId(tag.account_id)
            .withName(tag.name)
            .withColor(tag.color)
            .build();
    },
};

export default tagsApiRepository;
