<script>
import { mapActions } from 'vuex';

export default {
    name: 'CatalogItemDetails',
    data() {
        return {
            descriptionIsTruncated: true,
        };
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        filteredMetadata() {
            return this.item.metadata.filter((data) => data.attribute_name !== 'images');
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),

        truncateText(text) {
            if (text.length > 400) {
                // This will return the first 500 characters of the text and append '...' at the end
                return text.substring(0, 400) + '...';
            }
            return text; // Return the text as is if it's 500 characters or shorter
        },

        handleImageError(event) {
            event.target.src =
                'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffoto%20perfil%20usuario.png?alt=media&token=5470816a-d159-4958-aae2-5837c747339c';
        },
    },
};
</script>

<template>
    <div>
        <div class="dcid__close_bar">
            <md-button @click="closeModal()" class="md-just-icon"><md-icon>close</md-icon></md-button>
        </div>
        <md-dialog-content>
            <div class="dcid__main-panel">
                <div class="dcid__photo-carousel">
                    <carousel
                        :scrollPerPage="false"
                        :perPage="1"
                        :paginationEnabled="true"
                        :paginationPadding="5"
                        :paginationSize="5"
                    >
                        <slide v-for="image in item.images">
                            <img @error="handleImageError" :src="image" alt="photo" />
                        </slide>
                    </carousel>
                </div>

                <div class="dcid__item-data">
                    <div class="md-title">{{ item.name }}</div>
                    <div v-if="descriptionIsTruncated" class="md-caption">
                        {{ truncateText(item.description) }}
                        <span @click="descriptionIsTruncated = false" class="dcid__see-more-link">see more</span>
                    </div>
                    <div v-else class="md-caption">{{ item.description }}</div>
                    <div class="md-body-1"><strong>Metadata:</strong></div>
                    <div class="dcid__metadata-field">
                        <div v-for="data in filteredMetadata">
                            <div class="md-caption">
                                <strong>{{ data.attribute_name }}:</strong> {{ data.attribute_value }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-dialog-content>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/md/variables';
.dcid__close_bar {
    text-align: end;
}

.dcid__main-panel {
    display: flex;
    //padding: 1rem;
    gap: 2rem;
    @media (max-width: 959px) {
        flex-direction: column;
    }
}

.dcid__photo-carousel {
    width: 100%;
    max-width: 500px;
}

.dcid__item-data {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 500px;
    max-height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
}

.dcid__metadata-field {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 200px;
}

.dcid__see-more-link {
    text-decoration: underline;
    cursor: pointer;
    color: $brand-primary;
}
</style>
