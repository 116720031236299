<!-- eslint-disable prettier/prettier -->
<template>
    <div>
        <div
            :class="{
                'vac-box-search': showSearchBar,
                'vac-box-empty': !showSearchBar,
            }"
        >
            <template v-if="showSearch">
                <!-- v-if="!loadingRooms && rooms.length" -->
                <div v-if="!loadingRooms" class="vac-icon-search">
                    <slot name="search-icon">
                        <svg-icon name="search" />
                    </slot>
                </div>
                <!-- v-if="!loadingRooms && rooms.length" -->

                <input
                    v-if="!loadingRooms"
                    type="search"
                    ref="searchInput"
                    :placeholder="textMessages.SEARCH"
                    autocomplete="off"
                    class="vac-input"
                    @input="$emit('search-room', $event)"
                    v-model="searchParam"
                />
            </template>
            <div v-if="showAddRoom" class="vac-svg-button vac-add-icon" @click="$emit('add-room')">
                <slot name="add-icon">
                    <svg-icon name="add" />
                </slot>
            </div>
        </div>
        <!-- <div class="custom-filters-bar">
            <md-chip @click="openFilters" class="active-chip button-like-chip md-body-1">
                <div style="display: flex; align-items: center">
                    <svg-icon class="filter-icon" name="filters" />
                    <div>Filters</div>
                </div>
            </md-chip>

            Agent filter
            <md-menu :mdCloseOnClick="true" md-size="medium" md-align-trigger>
                <md-chip md-menu-trigger class="active-chip md-body-1">
                    <div style="display: flex; align-items: center">
                        <svg-icon class="filter-icon" :name="agent_icon(activeAgentFilter)" />
                        <div>{{ getAgentFilterName(activeAgentFilter) }}</div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="15px"
                            fill="#000000DD"
                        >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 10l5 5 5-5z" />
                        </svg>
                    </div>
                </md-chip>
                <md-menu-content>
                    <ul class="menu-items">
                        <li
                            v-for="option in agentsFilterOptions"
                            :index="option"
                            @click="activateAgentFilter(option)"
                            class="md-body-1"
                            :class="{ 'menu-item-active': activeAgentFilter === option }"
                        >
                            <svg-icon :name="agent_icon(option)" />
                            {{ getAgentFilterName(option) }}
                        </li>
                    </ul>
                </md-menu-content>
            </md-menu>

            Sentiment filter
            <md-menu :mdCloseOnClick="true" md-size="medium" md-align-trigger>
                <md-chip md-menu-trigger class="active-chip md-body-1">
                    <div style="display: flex; align-items: center">
                        <svg-icon class="filter-icon" :name="sentimentIcon(activeSentimentFilter)" />
                        <div>{{ getSentimentFilterName(activeSentimentFilter) }}</div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="15px"
                            fill="#000000DD"
                        >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 10l5 5 5-5z" />
                        </svg>
                    </div>
                </md-chip>
                <md-menu-content>
                    <ul class="menu-items">
                        <li
                            v-for="option in sentimentFilterOptions"
                            @click="activateSentimentFilter(option)"
                            :index="option"
                            class="md-body-1"
                            :class="{ 'menu-item-active': activeSentimentFilter === option }"
                        >
                            <svg-icon :name="sentimentIcon(option)" />
                            {{ getSentimentFilterName(option) }}
                        </li>
                    </ul>
                </md-menu-content>
            </md-menu>

            <md-chip @click="activeUnreadFilter" :class="{ 'active-chip': isUnreadDisabledFilter }"
        class="md-body-1">Unread</md-chip>
        </div> -->
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'RoomsSearch',
    components: { SvgIcon },

    data() {
        return {
            searchParam: this.searchParam,
            activeAgentFilter: 'All',
            agentsFilterOptions: ['All', 'Support Agent', 'Sales Agent', 'Human Agent', 'Returns Agent'],

            activeSentimentFilter: 'ALL',
            sentimentFilterOptions: ['ALL', 'UNDEFINED', 'POSITIVE', 'NEGATIVE'],
            isUnreadDisabledFilter: false,
        };
    },

    props: {
        textMessages: { type: Object, required: true },
        showSearch: { type: Boolean, required: true },
        showAddRoom: { type: Boolean, required: true },
        rooms: { type: Array, required: true },
        loadingRooms: { type: Boolean, required: true },
        avisar: { type: Function },
    },

    emits: ['search-room', 'add-room', 'open-filters'],

    computed: {
        showSearchBar() {
            return this.showSearch || this.showAddRoom;
        },
    },

    methods: {
        openFilters() {
            this.$emit('open-filters');
        },
        getAgentFilterName(agent) {
            if (agent === 'All') return 'All Agents';
            return agent;
        },
        getSentimentFilterName(sentiment) {
            if (sentiment === 'ALL') return 'All Sentiments';
            if (sentiment === 'UNDEFINED') return 'Not Analyzed';
            return sentiment
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        agent_icon(agent) {
            let iconName;
            switch (agent) {
                case 'Support Agent':
                    iconName = 'SUPPORT_AGENT';
                    break;
                case 'Sales Agent':
                    iconName = 'SALES_AGENT';
                    break;
                case 'Human Agent':
                    iconName = 'HUMAN_AGENT';
                    break;
                case 'Returns Agent':
                    iconName = 'RETURN_EXCHANGE_AGENT';
                    break;
                default:
                    iconName = 'FILTER_ALL';
                    break;
            }
            return iconName;
        },
        sentimentIcon(sentiment) {
            if (sentiment === 'UNDEFINED') return 'NOT_ANALYZED';
            if (sentiment === 'ALL') return 'FILTER_ALL';
            return sentiment;
        },
        activeUnreadFilter() {
            this.isUnreadDisabledFilter = !this.isUnreadDisabledFilter;
        },
        getActiveAgentFilterCode() {
            let filter_value = '';
            switch (this.activeAgentFilter) {
                case 'Support Agent':
                    filter_value = 'SUPPORT_AGENT';
                    break;
                case 'Sales Agent':
                    filter_value = 'SALES_AGENT';
                    break;
                case 'Human Agent':
                    filter_value = 'HUMAN_AGENT';
                    break;
                case 'Returns Agent':
                    filter_value = 'RETURN_EXCHANGE_AGENT';
                    break;
                default:
                    filter_value = 'ALL';
                    break;
            }
            return filter_value;
        },
        activateAgentFilter(filter) {
            this.activeAgentFilter = filter;
            let filter_value = this.getActiveAgentFilterCode();
            this.activateFilter(filter_value, this.activeSentimentFilter);
        },

        activateSentimentFilter(sentiment) {
            this.activeSentimentFilter = sentiment;
            this.activateFilter(this.getActiveAgentFilterCode(), sentiment);
        },

        activateFilter(agent = 'ALL', satisfaction = 'ALL') {
            this.$emit('filter-activated', agent, satisfaction);
        },
    },

    mounted() {
        const search = this.$route.fullPath.split('?search=')[1];
        if (search) {
            this.searchParam = search;
            this.avisar(this.searchParam);
            this.$emit('params', this.searchParam);
        }
        this.$emit('focus-input');
    },

    watch: {
        loadingRooms(newValue) {
            if (!newValue) {
                this.$nextTick(() => {
                    this.$refs.searchInput.focus();
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.filter-icon {
    height: 13px;
    width: 13px;
    margin-right: 4px;
    fill: $mdb-text-color-primary;
}

.menu-items {
    list-style: none;
    padding: 0;
}

.menu-items li {
    display: flex;
    text-align: start;
    align-items: center;
    padding: 5px 0;
    padding-left: 20px;
    border-radius: 12px;
    cursor: pointer;

    svg {
        height: 15px;
        width: 15px;
        margin-right: 5px;
    }
}

.menu-items li:hover {
    background-color: #f5f5f5;
}

.menu-item-active {
    background-color: #f5f5f5;
}

.custom-filters-bar {
    padding: 0px 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .md-chip {
        margin-top: 0px;
        cursor: pointer;
    }

    .active-chip {
        background-color: white !important;
        color: $mdb-text-color-primary !important;
        border: 1px solid #e1e5e8;
        border-radius: 6px !important;
    }

    .md-menu {
        margin-right: 5px;
    }

    .button-like-chip {
        transition: all 0.2s ease;

        &:hover {
            background-color: #f5f5f5 !important;
            transform: translateY(-1px);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        &:active {
            transform: translateY(0px);
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        }
    }
}
</style>
