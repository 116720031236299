<script>
import { mapActions } from 'vuex';
import TagSelector from '@/lib/RoomsList/FiltersSideBar/TagSelector.vue';

export default {
    name: 'EditTags',

    components: {
        TagSelector,
    },

    props: {
        tags: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            localTags: [],
            isLoading: false,
            isTagSelectorOpen: false,
        };
    },

    created() {
        this.localTags = [...this.tags];
    },

    computed: {
        canSave() {
            return JSON.stringify(this.localTags) !== JSON.stringify(this.tags);
        },
    },

    methods: {
        ...mapActions('modalStore', ['closeModal']),

        handleTagsChange(selectedTags) {
            this.localTags = selectedTags;
        },

        handleDropdownToggle(isOpen) {
            this.isTagSelectorOpen = isOpen;
        },

        async handleSave() {
            this.isLoading = true;
            try {
                this.$emit('modalEvent', {
                    eventName: 'update-tags',
                    eventPayload: this.localTags,
                });
                this.closeModal();
            } catch (error) {
                console.error('Error updating tags:', error);
                this.$toasted.error('Failed to update tags. Please try again.', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<template>
    <div class="edit-tags" :class="{ 'edit-tags--expanded': isTagSelectorOpen }">
        <div class="edit-tags__header">
            <div class="edit-tags__title-container">
                <span class="material-symbols-outlined edit-tags__icon">sell</span>
                <p class="edit-tags__title md-title">Edit Tags</p>
            </div>
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>

        <md-content class="edit-tags__content">
            <div class="edit-tags__selector-wrapper">
                <TagSelector
                    :preselected-tags="localTags"
                    @update:selected="handleTagsChange"
                    @dropdown-toggle="handleDropdownToggle"
                />
            </div>
        </md-content>

        <div class="edit-tags__footer">
            <md-button class="md-gray md-outline" @click="closeModal()">Cancel</md-button>
            <md-button class="md-primary" @click="handleSave" :disabled="isLoading || !canSave">
                {{ isLoading ? 'Saving...' : 'Save' }}
            </md-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.edit-tags {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 400px;
    max-width: 100%;
    border-radius: 4px;
    position: relative;
    min-height: 200px;
    transition: min-height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &--expanded {
        min-height: 380px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        height: 48px;
    }

    &__title-container {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
    }

    &__icon {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.54);
    }

    &__content {
        padding: 0 16px;
        overflow: visible !important;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__selector-wrapper {
        position: relative;
        z-index: 1100;
        width: 100%;
        padding-top: 8px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 8px 16px;
        position: sticky;
        bottom: 0;
    }
}

::v-deep .md-content {
    overflow: visible !important;
}
</style>
