<template>
    <div class="filter-actions-section">
        <div class="filter-actions-section__filters">
            <md-chip @click="$emit('open-filters')" class="active-chip button-like-chip md-body-1">
                <div style="display: flex; align-items: center">
                    <svg-icon class="filter-icon" name="filters" />
                    <div>Filters</div>
                </div>
            </md-chip>
            <md-chip @click="$emit('load-preset')" class="active-chip button-like-chip md-body-1">
                <div style="display: flex; align-items: center">
                    <svg-icon class="filter-icon" name="bookmarks" />
                    <div>Lists</div>
                </div>
            </md-chip>
        </div>
        <div v-if="canSavePreset" class="filter-actions-section__presets">
            <md-chip @click="$emit('save-preset')" class="active-chip button-like-chip md-body-1">
                <div style="display: flex; align-items: center">
                    <svg-icon class="filter-icon" name="bookmark_add" />
                    <div>Save</div>
                </div>
            </md-chip>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'FilterActionsSection',
    components: { SvgIcon },
    props: {
        canSavePreset: { type: Boolean, default: false },
    },
    emits: ['open-filters', 'save-preset', 'load-preset'],
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';
.filter-actions-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px 8px 16px;
}

.md-chip {
    margin-top: 0px;
    cursor: pointer;
}

.active-chip {
    background-color: white !important;
    color: $mdb-text-color-primary !important;
    border: 1px solid #e1e5e8;
    border-radius: 6px !important;
}

.button-like-chip {
    transition: all 0.2s ease;

    &:hover {
        background-color: #f5f5f5 !important;
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0px);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
}

.filter-icon {
    height: 13px;
    width: 13px;
    margin-right: 4px;
    fill: $mdb-text-color-primary;
}

.filter-actions-section__presets {
    .active-chip {
        background-color: #431ed9 !important;
        color: white !important;

        .filter-icon {
            fill: white;
        }
    }
}
</style>
